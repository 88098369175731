<template>
  <Toast />
  <div class="p-inputgroup ">
    <!-- <FormInputText  
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder" 
        :type="type"
        :disabled="disabled"
        v-model="inputValue" 
        :readonly="readonly"
        :class="{ 'p-invalid': valid === false }"
        :wrapperClass="wrapperClass" 
        :label="label"  
        @input="generateBarcode">
      </FormInputText> 
    <Button @click="convertSvgToImage" severity="success"><i class="pi pi-clone" ></i></Button> -->
    <FormInputText 
      :label = "label"
      :autocomplete = "autocomplete"
      :placeholder = "placeholder"
      :type="type"
      :disabled="disabled"
      v-model="inputValue"
      :readonly="readonly"
      :wrapperClass="wrapperClass"
      :barcode = "true"
      @input = "generateBarcode"
      @barcode = "convertSvgToImage"
    />
    <svg v-show="inputValue"   id="imagen" ref="barcode"></svg>
  </div>
</template>

<script>
 import JsBarcode from 'jsbarcode';
 import FormInputText from '../general/FormInputText.vue'
 import {  Toast,ErrorToast } from '../../utilities/General';
// import * as XLSX from 'xlsx'; // importar la biblioteca XLSX
//import svgToImage from 'svg-to-image';

export default {
  data() {
    return {
      inputValue: '',
    };
  },
  props: {
    name: {
      type: [String, null],
      default: null
    },
    autocomplete: {
      type: [String, null],
      default: "off"
    },
    tooltip: {
        type: [String, null],
        default: null
    },
    mask: {
        type: [String, null],
        default: null
    },
    placeholder: {
        type: [String, null],
        default: null
    },
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    wrapperClass: {
        type: String,
        default: "field"
    },
    readonly:{
        type: [Boolean, null],
        default: null
    },
    label: {
        type: [String, null],
        default: null
    },
    type: {
        type: [String, null],
        default: "text"
    },
    valid: {
        type: Boolean,
        //Todos son validos antes del submit
        default: true
    },
    validation: {
        type: String,
        default: "Favor de llenar el campo"
    },
    textArea: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    search: {
        type: Boolean,
        default: false
    },
    clear: {
        type: Boolean,
        default: false
    },
    add: {
        type: Boolean,
        default: false
    },
    barcode: {
        type: Boolean,
        default: false
    },
    rows: {
        type: Number,
        default: 1
    },
    barcodeText: {
        type: String,
        default: ''
    }
  },
  emits: {
    onBarcode: null,
  },
  components:{FormInputText},
  methods: {
    generateBarcode() {
      if(this.inputValue == "") this.$refs.barcode = this.inputValue
      else {
        JsBarcode(this.$refs.barcode, this.inputValue);
        this.$emit("onBarcode", this.inputValue);
      }
    },
    convertSvgToImage() {
      var svgElement = document.getElementById("imagen");

      // Create a new Image element
      var imgElement = new Image();

      // Create a new XMLSerializer object
      var serializer = new XMLSerializer();

      // Serialize the SVG element to a string
      var svgString = serializer.serializeToString(svgElement);

      // Set the source of the IMG element to a data URI representation of the SVG string
      imgElement.src = "data:image/svg+xml;base64," + btoa(svgString);

      // Add the IMG element to the DOM

      var textArea = document.createElement("textarea");
      textArea.value = document.body.appendChild(imgElement);

      //document.body.appendChild(imgElement);

      // Crea un nuevo objeto IMG
      var img = document.createElement("img");

      // Establece la URL de la imagen en el atributo 'src'
      img.src = imgElement.src;

      // Cuando la imagen se carga, la dibuja en un canvas y convierte el canvas en un objeto Blob
      img.onload = function() {
        const { ClipboardItem } = window;
        // Crea un nuevo canvas y establece sus dimensiones para que coincidan con las de la imagen
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        // Dibuja la imagen en el canvas
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        // Convierte el canvas en un objeto Blob
        canvas.toBlob(function(blob) {
          // Copia el objeto Blob al portapapeles utilizando la API del Portapapeles
          navigator.clipboard.write([new ClipboardItem({'image/png': blob})])
            .then(function() {
              console.log("Imagen copiada correctamente")
            })
            .catch(function() {
              this.$toast.add(new ErrorToast("Error al copiar al portapapeles"));
            });
        }, "image/png");
      };
      this.$toast.add(
        new Toast({
            summary: 'Creacion',
            detail: 'Imagen copiada en el portapapeles correctamente',
        })
      );
      document.body.removeChild(imgElement);
}},
  watch: {
    inputValue(newValue) {
      this.$emit("input", newValue);
    },
    modelValue(newValue) {
      this.localValue = newValue;
    },
    barcodeText(newValue){
      this.inputValue = newValue;
    }
  },
};

</script>


